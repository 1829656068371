import { useSiteConfig } from "@context/siteConfig";
import DesktopFooter from "./Footer";
import MobileFooter from "./MobileFooter";

const Footer = () => {
  const { isMobile } = useSiteConfig();
  return <>{isMobile ? <MobileFooter /> : <DesktopFooter />}</>;
};

export default Footer;
