import AppInstallButtons from "@components/_shared/AppInstallButtons";
import SocialMediaLinks from "@components/_shared/SocialMediaLinks";
import { useSiteConfig } from "@context/siteConfig";
import { useWebEngageTracking } from "@context/tracking/webEngage";
import { useTranslations } from "@context/translations";
import { buildLanguageClickAttrs } from "@helpers/webEngage/clickEventBuilders";
import { WebEngageClickEvent } from "@shopcashTypes/webEngageTracking";
import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import styles from "./Footer.module.scss";

const Footer: React.FC = () => {
  const router = useRouter();
  const { isRtl, defaultLocale } = useSiteConfig();
  const translate = useTranslations();
  const { trackWebEngageClickEvent } = useWebEngageTracking();

  const handleLocaleClick = (locale: string) => {
    trackWebEngageClickEvent(
      WebEngageClickEvent.languageClick,
      buildLanguageClickAttrs(locale)
    );
  };

  return (
    <footer
      className={clsx(styles.footer, isRtl && styles.rtl)}
      data-testid="footer-desktop"
    >
      <div className={styles.container}>
        <div className={styles.lists}>
          <div className={styles.list}>
            <h3>{translate("footer_about")}</h3>

            <ul>
              <li>
                <Link href="/about">
                  <a data-testid="footer-about-link">
                    {translate("about_shopcash")}
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/privacy-policy">
                  <a data-testid="footer-privacy-link">
                    {translate("privacy_policy")}
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/terms">
                  <a data-testid="footer-terms-link">
                    {translate("terms_conditions")}
                  </a>
                </Link>
              </li>
            </ul>
          </div>

          <div className={styles.list}>
            <h3>{translate("explore_footer_menu")}</h3>

            <ul>
              <li>
                <Link href="/category/all-stores">
                  <a data-testid="footer-all-stores-link">
                    {translate("footer_all_stores")}
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/deals">
                  <a>{translate("deals")}</a>
                </Link>
              </li>
              <li>
                <a
                  href="https://shopcash.blog/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {translate("Blog")}
                </a>
              </li>
            </ul>
          </div>

          <div className={styles.list}>
            <h3>{translate("help_title")}</h3>

            <ul>
              <li>
                <Link href="/faq">
                  <a data-testid="footer-faq-link">{translate("faq")}</a>
                </Link>
              </li>
              <li>
                <Link href="/contact-us">
                  <a data-testid="footer-contact-link">
                    {translate("contact_us")}
                  </a>
                </Link>
              </li>
            </ul>
          </div>

          <div className={styles.socialMediaAndAppLinks}>
            <div className={styles.appInstallLinks}>
              <AppInstallButtons />
            </div>
            <div className={styles.socialMediaLinks}>
              <SocialMediaLinks />
            </div>
          </div>
        </div>

        <div className={styles.sole}>
          <div
            className={styles.languageSelector}
            data-testid="language-selector-footer"
          >
            <Link
              href={{
                pathname: router.pathname,
                query: router.query,
              }}
              locale={defaultLocale.includes("en") ? defaultLocale : "en"}
            >
              <a
                className={clsx(
                  router.locale.includes("en") && styles.selected
                )}
                onClick={() => handleLocaleClick("English")}
              >
                English
              </a>
            </Link>
            <Link
              href={{
                pathname: router.pathname,
                query: router.query,
              }}
              locale={defaultLocale.includes("ar") ? defaultLocale : "ar"}
            >
              <a
                className={clsx(
                  router.locale.includes("ar") && styles.selected
                )}
                onClick={() => handleLocaleClick("Arabic")}
              >
                {translate("arabic")}
              </a>
            </Link>
          </div>
          <div className={styles.logoCopyrightContainer}>
            <Link href="/">
              <a className={styles.logo}>
                <img
                  width="148"
                  src="https://assets.wego.com/image/upload/w_296/v1603273048/web/shopcash/shopcash-logo-wording-white.png"
                  alt="ShopCash logo"
                />
              </a>
            </Link>

            <div className={styles.copyright}>
              &copy; {new Date().getFullYear()} ShopCash Inc.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
