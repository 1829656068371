import clsx from "clsx";
import Image, { ImageProps } from "next/image";
import styles from "./NextImage.module.scss";

interface NextImageWrapperProps {
  flipped?: boolean;
  src: string;
}

const NextImageComponentWrapper: React.FC<
  ImageProps & NextImageWrapperProps
> = ({ flipped = false, src, className, ...props }) => {
  if (!src) return null;

  flipped && (className = clsx(className, styles.flipped));

  if (src.includes("assets.wego.com")) {
    return <Image className={className} src={transformImage(src)} {...props} />;
  }

  return <img className={className} src={src} alt={props.alt} />;
};

const transformImage = (url: string): string => {
  // This is for cloudinary images only.
  // next/image adds these `f_auto,c_limit,w_3840,q_auto`
  // "c_fill,g_auto" will crop image
  const transformations = ["c_fill", "g_auto", "fl_lossy"];

  return url?.replace(
    "https://assets.wego.com/image/upload",
    `${transformations.join(",")}`
  );
};

export default NextImageComponentWrapper;
