import FacebookIcon from "@icons/facebookIcon.svg";
import InstagramIcon from "@icons/instagramIcon.svg";
import TelegramIcon from "@icons/telegramIcon.svg";
import TwitterXIcon from "@icons/twitterXIcon.svg";
import React from "react";

const SocialMediaLinks: React.FC = () => {
  return (
    <>
      <a
        href="https://www.facebook.com/ShopCashArabia/"
        target="_blank"
        rel="noopener noreferrer"
        data-testid="facebook-link"
      >
        <FacebookIcon />
      </a>
      <a
        href="https://twitter.com/ShopCashArabia"
        target="_blank"
        rel="noopener noreferrer"
        data-testid="twitter-link"
      >
        <TwitterXIcon />
      </a>
      <a
        href="https://www.instagram.com/shopcasharabia/"
        target="_blank"
        rel="noopener noreferrer"
        data-testid="instagram-link"
      >
        <InstagramIcon />
      </a>
      <a
        href="https://t.me/ShopCash_Cashback"
        target="_blank"
        rel="noopener noreferrer"
        data-testid="telegram-link"
      >
        <TelegramIcon />
      </a>
    </>
  );
};

export default SocialMediaLinks;
