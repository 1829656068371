import Image from "@components/_shared/NextImage/v2";
import { useSiteConfig } from "@context/siteConfig";

interface AppInstallButtonsProps {
  huaweiUrlParamsString?: string;
  appStoreLink?: string;
  playStoreLink?: string;
}

const AppInstallButtons: React.FC<AppInstallButtonsProps> = ({
  appStoreLink = "https://shopcash.onelink.me/ra0F/ios",
  playStoreLink = "https://shopcash.onelink.me/ra0F/shopcash",
  huaweiUrlParamsString = "&id=8e9d5476bcb342139e2ce781035a18c7&channelId=web_footer&s=88FD2BAE3859FC68E3DB4C9C298E0C4618CDF805071DEDFD52466C37F3719597",
}) => {
  const { locale } = useSiteConfig();

  const huaweiAppInstallLink = `hiapplink://com.huawei.appmarket?appId=C104199059&detailType=0&v=&callType=AGDLINK&installType=0000${huaweiUrlParamsString}`;

  return (
    <>
      <a href={playStoreLink} target="_blank" rel="noopener noreferrer">
        <Image
          src={`https://zen.shopcash.com/assets/static/icons/play-store-btn-${locale}.png`}
          layout="fill"
          alt="Play Store Button"
        />
      </a>
      <a href={appStoreLink} target="_blank" rel="noopener noreferrer">
        <Image
          src={`https://zen.shopcash.com/assets/static/icons/app-store-btn-${locale}.png`}
          layout="fill"
          alt="App Store Button"
        />
      </a>
      <a href={huaweiAppInstallLink} target="_blank" rel="noopener noreferrer">
        <Image
          src={`https://zen.shopcash.com/assets/static/icons/huawei-store-btn-${locale}.png`}
          layout="fill"
          alt="Huawei Store Button"
        />
      </a>
    </>
  );
};

export default AppInstallButtons;
