import { getLocalDateAndTimeSeperately } from "@helpers/date";
import { mapStoreType } from "@helpers/webEngage/mapper";
import { Source } from "@shopcashTypes/webEngageTracking";

export const buildTrendingStoreClickAttrs = (store: StoreSummary) => {
  return {
    Source: Source.shopcash,
    "Store Name": store.name,
    "Store ID": store.id,
  };
};

export const buildHomePageBannerClickAttrs = (store: StoreSummary) => {
  return {
    Source: Source.shopcash,
    "Store Name": store.name,
    "Store ID": store.id,
    "Store Type": mapStoreType(store.storeType),
  };
};

export const buildHomePageDealClickAttrs = (
  storeDeal: StoreDeal & { dealType: string }
) => {
  const [expiryDate, expiryTime] = getLocalDateAndTimeSeperately(
    storeDeal.endAt
  );

  return {
    Source: Source.shopcash,
    "Store Name": storeDeal.store.name,
    "Store ID": storeDeal.store.id,
    "Store Type": mapStoreType(storeDeal.store.storeType),
    "Deal Type": storeDeal.dealType,
    "Coupon Code": storeDeal.couponCode,
    "Expiry Date": expiryDate,
    "Expiry Time": expiryTime,
    "Deal Title": storeDeal.title,
    "Cashback Rate": storeDeal.store.featuredCashback?.cashbackText,
  };
};

export const buildLanguageClickAttrs = (locale: string) => {
  return {
    Source: Source.shopcash,
    Language: locale,
  };
};

export const buildEmailOptInClickAttrs = (isOptIn: boolean) => {
  return {
    Source: Source.shopcash,
    "Optin Flag": isOptIn,
  };
};
